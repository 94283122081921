<template>
  <b-card title="Rekap Izin Kelas">
    <b-row>
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-1" @submit.prevent>
            <b-row>
              <b-col md="12" xl="6">
                <validation-provider #default="{ errors }" name="academic_year" rules="required">
                  <b-form-group label="Tahun Akademik" label-for="academic_year" :state="errors.length > 0 ? false : null"
                    label-cols-md="4">
                    <v-select id="academic_year" v-model="dataParent.academic_year"
                      :reduce="(class_id) => class_id.real_id" placeholder="Pilih Tahun Akademik"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listTA" label="name" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="semester" rules="required">
                  <b-form-group label="Semester" label-for="semester" :state="errors.length > 0 ? false : null"
                    label-cols-md="4">
                    <v-select id="semester" v-model="dataParent.semester" :reduce="(semester) => semester.value"
                      placeholder="Pilih Semester" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="listSemester" label="text" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group label="Periode Awal" label-for="start_date" label-cols-md="4">
                  <validation-provider #default="{ errors }" name="start_date" rules="required">
                    <b-form-input id="start_date" v-model="dataParent.start_date" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Periode Awal" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Periode Akhir" label-for="end_date" label-cols-md="4">
                  <validation-provider #default="{ errors }" name="end_date" rules="required">
                    <b-form-input id="end_date" v-model="dataParent.end_date" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Periode Akhir" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col md="12" xl="6">
                <validation-provider #default="{ errors }" name="academic_class_id" rules="required">
                  <b-form-group label="Kelas" label-for="academic_class_id" :state="errors.length > 0 ? false : null"
                    label-cols-md="4">
                    <v-select id="academic_class_id" v-model="dataParent.academic_class_id"
                      :reduce="(academic_class_id) => academic_class_id.real_id" placeholder="Pilih Kelas"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listClass" label="class_desc" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="absent_type" rules="required">
                  <b-form-group label="Jenis Izin" label-for="absent_type" :state="errors.length > 0 ? false : null"
                    label-cols-md="4">
                    <v-select id="absent_type" v-model="dataParent.absent_type"
                      :reduce="(absent_type) => absent_type.real_id" placeholder="Pilih Jenis Izin"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listAbsent"
                      label="absent_type_desc" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="academic_subject_id" rules="required">
                  <b-form-group label="Mata Pelajaran" label-for="academic_subject_id"
                    :state="errors.length > 0 ? false : null" label-cols-md="4">
                    <v-select id="academic_subject_id" v-model="dataParent.academic_subject_id"
                      :reduce="(academic_subject_id) => academic_subject_id.real_id" placeholder="Pilih Mata Pelajaran"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listMP" label="subject" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="3">
                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="GetAllData">
                    View
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-1"
                    @click="ExportExcel">
                    Export
                  </b-button>
                </b-form-group>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
      </b-col>
    </b-row>

    <table class="table" id="recaptable">
      <thead>
        <tr>
          <th>No</th>
          <th>NISN</th>
          <th>Nama Lengkap</th>
          <th>L/P</th>
          <th>Jenis Izin</th>
          <th>Keterangan izin</th>
          <th>Jam Mulai</th>
          <th>Jam Selesai</th>
          <th>Mata Pelajaran</th>
          <th>Guru Pengajar</th>
          <th>Guru Piket</th>
          <th>Jenis Kendaraan</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { codeAdvance } from './code'

import 'jquery/dist/jquery.min.js';
import 'datatables.net';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import axios from 'axios';
import $ from 'jquery';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    // BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mounted() {
    $('#datatable').DataTable({
      processing: true,
      responsive: true,
    });
  },
  data: function () {
    return {
      products: [],
    }
  },
  toEditForm(pesan) {
    alert(pesan);
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      codeAdvance,
      searchTerm: '',
      modalRemove: '',
      dataParent: {
        academic_year: '',
        semester: '',
        start_date: this.NowDateFormat(),
        end_date: this.NowDateFormat(),
        academic_class_id: '',
        academic_subject_id: '',
        absent_type: '',
        subject_id: '',
      },
      DataProduct: [],
      listClass: [],
      listTA: [],
      listAbsent: [],
      listMP: [],
      listSemester: [
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
        { value: '5', text: '5' },
        { value: '6', text: '6' },
        { value: '7', text: '7' },
        { value: '8', text: '8' },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getClass()
    this.getTA()
    this.getAbsent()
    this.getMP()
  },
  methods: {
    NowDateFormat() {
      var NowDate = Date.now();
      var d = new Date(NowDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    async getClass() {
      try {
        const response = await this.$http.get('/masterclasses')
        this.listClass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getTA() {
      try {
        const response = await this.$http.get('/academicyears')
        this.listTA = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getAbsent() {
      try {
        const response = await this.$http.get('/absenttypes')
        this.listAbsent = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getMP() {
      try {
        const response = await this.$http.get('/academicsubjects?status=all')
        this.listMP = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Data.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeData(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddPegawai() {
      this.$router.push({ name: 'data-pegawai-add' })
    },
    toEditForm(pesan) {
      alert(pesan);
    },

    async ExportExcel() {
      var table = $('#recaptable').DataTable();
      table.page.len(-1).draw();
      window.open('data:application/vnd.ms-excel,' +
        encodeURIComponent($('#recaptable').parent().html()));
      setTimeout(function () {
        table.page.len(10).draw();
      }, 1000)
    },

    async GetAllData() {
      try {
        var StartDate = document.getElementById('start_date');
        var EndDate = document.getElementById('end_date');
        const Sd = StartDate.value.substring(0, 10);
        const Ed = EndDate.value.substring(0, 10);

        var academic_year = this.dataParent.academic_year;
        var semester = this.dataParent.semester;
        var academic_class_id = this.dataParent.academic_class_id;
        var academic_subject_id = this.dataParent.academic_subject_id;
        var absent_type = this.dataParent.absent_type;

        const response = await this.$http.get(`/absents?academic_year=${academic_year}&semester=${semester}&start_date=${Sd}&end_date=${Ed}&academic_class_id=${academic_class_id}&academic_subject_id=${academic_subject_id}&absent_type=${absent_type}`)
        var DataRespone = response.data;
        var number = 1;
        var RecapTemp = [];
        $.each(DataRespone, function (i, item) {
          var JsonData = {
            "no": number,
            "start_date": item.start_date.substr(0, 10),
            "end_date": item.end_date.substr(0, 10),
            "student_id": item.student_id,
            "nisn": item.nisn,
            "name": item.student_name,
            "gender": item.gender,
            "absent_type_desc": item.absent_type_desc,
            "description": item.description,
            "start_study_hour": item.start_study_hour,
            "end_study_hour": item.end_study_hour,
            "subject_descprition": item.subject_descprition,
            "guru_mk": item.guru_mk,
            "guru_piket": item.guru_piket,
            "vehicle": item.vehicle,
          }
          RecapTemp.push(JsonData);
          number++;
        });
        this.DataProduct = this.RecapTemp;
        $('#recaptable').DataTable().destroy();
        var myTable = $('#recaptable').DataTable({
          "paging": true,
          "lengthChange": true,
          "searching": true,
          "ordering": true,
          "info": true,
          "autoWidth": true,
          "data": this.JsonData,
          "columns": [{
            "title": "No",
            "data": "no"
          },
          {
            "title": "Tanggal",
            "data": "start_date"
          },
          {
            "title": "Jenis Izin",
            "data": "absent_type_desc"
          }, {
            "title": "Keterangan izin",
            "data": "description"
          }
            , {
            "title": "Jam Mulai",
            "data": "start_study_hour"
          }
            , {
            "title": "Jam Selesai",
            "data": "end_study_hour"
          }
            , {
            "title": "Mata Pelajaran",
            "data": "subject_descprition"
          },
          {
            "title": "Guru Pengajar",
            "data": "guru_mk"
          }, {
            "title": "Guru Piket",
            "data": "guru_piket"
          }]
        });
        myTable.clear();
        $.each(RecapTemp, function (index, value) {
          myTable.row.add(value);
        });
        myTable.draw();
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    handleClick(id) {
      // Your code here
      alert(id);
    },
    UpdateClick(id) {
      this.$router.push({ name: 'absensi-data-kehadiran-edit', params: { id: id } })
    },
    DeleteClick(id) {
      alert(id);
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeData(val) {
      try {
        await this.$http.delete(`/kehadirans/${val}`)
        await this.GetAllData()
        this.showToast('success', 'top-center', 'Delete Data Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Data Not Success')
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
